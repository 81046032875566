import React from "react";
import {PropTypes} from "prop-types";
import {
    BtnSimple,
    BtnGroup,
    BtnSwitch,
    Icon,
    LinkSimple,
    Img,
    Iframe,
    List,
    ProgressBar,
    FormInputSimple,
    FormSelectSimple,
    FormRadioSimple,
    FormCheckboxSimple,
    FormInputRangeSimple,
  } from "getbasecore/Atoms";
  
  import {
    Pagination,
    Form,
    Alert,
    Table,
    Accordion,
    Breadcrumb,
    Dropdown,
    Tabs,
  } from "getbasecore/Molecules";
  
  import { Card } from "../../organisms/Card/Card";
  import "./hero.scss";
  // export { Modal } from "./src/components/molecules/Modal/Modal";
  // export { Tooltip } from "./src/components/molecules/Tooltip/Tooltip";
  
  export const Hero = ({
   
    imgSrc,
    imgCss,
    bkg,
    children,
    securance,
    price,
    css
  }) => {
    return (
<div className={`hero ${css}`}style={{ backgroundImage: `url(${bkg})` }}>

<div className="container container--grid" style={{ paddingTop: "40px"}}>
  <Img css={imgCss} src={imgSrc}></Img>

            <div className="hero-info">
              <div className="hero-info-securance">
              <hr className="vertical-hr"></hr>
                <div className="hero-info-securance-text">
                  <p className="h6-price">Tu selección</p>
                    <p className="h3.2">
                    {securance}
                    </p>
                </div>
              </div>
              
                <div className="hero-info-price">
                <hr className="vertical-hr"></hr>
                  <div className="hero-info-price-text">
                    <p className="h6-price">Precio</p>
                    <p className="h3.2" >{price}</p>
                  </div>
                </div>
            </div>
          </div>
{children}
</div>);


};
Hero.propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
      PropTypes.element,
      PropTypes.string,
    ]),
  };