import React, { createContext, useContext, useState } from "react";

const FormContext = createContext();

export const useFormContext = () => useContext(FormContext);

export const FormProvider = ({ children }) => {
  // const [formData, setFormData] = useState({
  //   securance: "Premium",
  //   price: "7.15",
  //   month: "Enero",
  //   year: "2025",
  //   business: "Hospitales",
  //   firstName: "Manuela",
  //   lastName: "Inclán",
  //   birthDate: "1993-12-29",
  //   dni: "71296504T",
  //   phone: "606387724",
  //   email: "manuela@flyingpigs.es",
  //   selectedOption: "yes",
  //   streetType: "Calle",
  //   streetAddress: "Calvo Asensio",
  //   streetNumber: "12",
  //   flat: "2C",
  //   zip: "28015",
  //   city: "Madrid",
  //   iban: "",
  //   frecuency: "",
  //   step: 1,
  //   legal1: true,
  //   legal2: false,
  //   subLegal1: "",
  //   subLegal2: "",
  //   subLegal3: "",
  // });

  const [formData, setFormData] = useState({
    securance: "",
    price: "",
    month: "",
    year: "",
    business: "",
    firstName: "",
    lastName: "",
    birthDate: "",
    dni: "",
    phone: "",
    email: "",
    selectedOption: "yes",
    streetType: "",
    streetAddress: "",
    streetNumber: "",
    flat: "",
    zip: "",
    city: "",
    iban: "",
    frecuency: "",
    step: 1,
    legal1: false,
    legal2: false,
    subLegal1: "",
    subLegal2: "",
    subLegal3: "",
  });
  const [totalPrice, setTotalPrice] = useState(0); // Estado para almacenar el precio total

  
  return (
    <FormContext.Provider value={{ formData, setFormData, totalPrice, setTotalPrice }}>
      {children}
    </FormContext.Provider>
  );
};
