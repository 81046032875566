import { React, useState, useEffect, Children } from "react";
import { PropTypes } from "prop-types";
import "./progress-steps.scss";
export const ProgressSteps = ({ css, value, max, children }) => {
  const valueFinal = value;
  //1,33
  const percentage = (value * 100) / max;
  return (
    <div className="progress-steps-holder">
      <div className="progress-steps">
        {[...Array(max)].map((_, index) => (
          <span
            key={index}
            className="progress-steps__step"
            style={{
              left: ((index * 100) / max) * (100 / (max - 1) / 100 + 1) + "%",
            }}
          />
        ))}
        {[...Array(value)].map((_, index) => (
          <span
            key={index}
            className="progress-steps__step-active"
            style={{
              left: ((index * 100) / max) * (100 / (max - 1) / 100 + 1) + "%",
            }}
          />
        ))}
      </div>
      {children}
    </div>
  );
};

ProgressSteps.propTypes = {
  css: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  max: PropTypes.string.isRequired,
};
