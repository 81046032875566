import { LinkSimple, Icon } from "getbasecore/Atoms";
import { BtnSimple } from "../../atoms/BtnSimple/BtnSimple";

import { useState } from "react";
import { useFormContext } from "../../../contexts/FormContext";
import { Form } from "getbasecore/Molecules";
import { ProgressSteps } from "../../atoms/ProgressSteps/ProgressSteps";
import { Hero } from "../../organisms/Hero/hero";
import { FormSelectSimple } from "../../atoms/FormInputSimple/FormSelectSimple";
import { useValidations } from "services/validations";

import { Card } from "../../organisms/Card/Card";
import { years } from "../../../data/constants";
import "./step2.scss";

export const Step2 = ({ onNext, filteredMonths, handleInputChange, handleBack }) => {
  const { formData, setFormData, totalPrice, setTotalPrice } = useFormContext();

  const { setErrors } = useValidations();
  const { errors, handleBlur } = useValidations();

  const [isValid, setIsValid] = useState(false);

  const handleValidation = () => {
    setErrors(validationsTotal(formData));
  };
  const validationsTotal = () => {
    if (!formData.year && (formData.year === "" || formData.year === "-1")) {
      errors.year = "Por favor, selecciona un 'Año'";
    }
    if (!formData.month && (formData.month === "" || formData.month === "-1")) {
      errors.month = "Por favor, selecciona un 'Mes'";
    }
  };
  const isValidForm = () =>
    formData.month !== "" && formData.year !== "" && formData.month !== "-1" && formData.year !== "-1";

  const onValidation = () => {
    // Validar el formulario
    if (isValidForm()) {
      // Si el formulario es válido, cambiar el estado de isValid a true
      setIsValid(true);
      onNext();
    } else {
      // Si el formulario no es válido, cambiar el estado de isValid a false
      setIsValid(false);
      handleValidation("year", formData.year);
      handleValidation("month", formData.month);
    }
  };
  return (
    <>
      <Hero
        css={"step2"}
        imgSrc={"img/logosanitassinfondoazul.png"}
        securance={formData.securance}
        price={totalPrice.toFixed(2) + "€"}></Hero>
      <Card css="container container--grid step">
        <LinkSimple css="button-back" aria="" onClick={handleBack}>
          <Icon css="icon--xs" icon="arrow-left" /> Volver
        </LinkSimple>
        <div>
          <p className="h3" id="titleStep2">
            2. Elige cuándo quieres empezar
          </p>
        </div>
        <Form css={`form__group`}>
          <fieldset>
            <FormSelectSimple
              label="Año * "
              id="year"
              name="year"
              required
              value={formData.year}
              onChange={(e) => handleInputChange(e)}
              onBlur={(e) => handleBlur(e)}
              validation="year">
              {years.map((year, index) => (
                <option key={index} value={year} selected={year === formData.year}>
                  {year}
                </option>
              ))}
            </FormSelectSimple>

            {errors.year && <p className="error">{errors.year}</p>}
          </fieldset>
          <fieldset>
            <FormSelectSimple
              label="Mes *"
              id="month"
              name="month"
              validation="month"
              onChange={(e) => handleInputChange(e)}
              onBlur={(e) => handleBlur(e)}>
              {filteredMonths.map((month, index) => (
                <option key={index} value={month} selected={month === formData.month}>
                  {month}
                </option>
              ))}
            </FormSelectSimple>
            {errors.month && <p className="error">{errors.month}</p>}
          </fieldset>
        </Form>
      </Card>
      <Card>
        <ProgressSteps value={2} max={4}>
          <BtnSimple
            id="nextStep2"
            type="button"
            css={isValidForm() === false ? "btn-simple--1 invalid" : "btn-simple--1"}
            aria="Siguiente"
            onClick={onValidation}>
            Siguiente <Icon css="icon--xs" icon="arrow-right" />
          </BtnSimple>
        </ProgressSteps>
      </Card>
    </>
  );
};
