import { useState } from "react";

export const useValidations = () => {


const [errors, setErrors] = useState({});
//const [errorsBeneficiary, setErrorsBeneficiary] = useState([]);

let regexName = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜüÇç\s]+$/;
  let regexEmail = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/;
  let regexDni = /^[XYZxyz]?[0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKEtrwagmyfpdxbnjzsqvhlcke]|[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKEtrwagmyfpdxbnjzsqvhlcke]$/i;
  let regexPhone = /^(((\+|00)34)?([6-7][0-9]{8}|[8-9][1-9][0-9]{7})|(\+|00)376([3-46][0-9]{5}|[7-8][0-9]{5})|(\+|00)(?!(34|376|212|351|33|49|44|350))[0-9]{4,16})$/;
  let regexZipCode = /^(0[1-9]|[1-9][0-9])[0-9]{3}$/;
  let regexIban = /^(ES)\s*\t*(\d{2})\s*\t*(\d{4})\s*\t*(\d{4})\s*\t*(\d{2})\s*\t*(\d{10})/;
  

  const validateYear = (value) => {
    if (!value || value === "-1") {
      return "Por favor, selecciona un 'Año'";
    }
    return '';
  };
  const validateMonth = (value) => {
    if (!value || value === "-1") {
      return "Por favor, selecciona un 'Mes'";
    }
    return '';
  };
  const validateBusiness = (value) => {
    if (!value.trim() || value === "-1") {
      return "Por favor, selecciona un 'Área de negocio'";
    }
    return '';
  };
  const validateFirstName = (value) => {
    if (!value.trim()) {
      return 'El "Nombre" es necesario';
    } else if (!regexName.test(value.trim())) {
      return 'Introduce un "Nombre" válido';
    }
    return '';
  };
  const validateLastName = (value) => {
    if (!value.trim()) {
      return 'El "Apellido" es necesario';
    } else if (!regexName.test(value.trim())) {
      return 'Introduce un "Apellido" válido';
    }
    return '';
  };
  const validateBirthDate = (value) => {
    if (!value.trim()) {
      return "La 'Fecha de nacimiento' es necesaria";
    } else {
      const birthDate = new Date(value);
      const eighteenYearsAgo = new Date();
      eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);
  
      if (birthDate > eighteenYearsAgo) {
        return "Debes ser mayor de 18 años para registrarte";
      }
    }
    return '';
  };
  const validateDni = (value) => {
    if (!value.trim()) {
      return "El 'DNI' es necesario";
    } else if (!regexDni.test(value.trim())) {
      return "Introduce un 'DNI' válido";
    }
    return '';
  };
  const validatePhone = (value) => {
    if (!value.trim()) {
      return "El 'Teléfono' es necesario";
    } else if (!regexPhone.test(value.trim())) {
      return "Introduce un 'Teléfono' válido";
    }
    return '';
  }; 
  const validateEmail = (value) => {
    if (!value.trim()) {
      return "El 'Email' es necesario";
    } else if (!regexEmail.test(value.trim())) {
      return "Introduce un 'Email' válido";
    }
    return '';
  };
  const validateStreetType = (value) => {
    if (!value || value === "-1") {
      return "Por favor, selecciona un 'Tipo de vía'";
    }
    return '';
  }; 
  const validateStreetAddress = (value) => {
    if (!value.trim()) {
      return "El 'Nombre de la vía' es necesario";
    }
    return '';
  };
  const validateStreetNumber = (value) => {
    if (!value.trim()) {
      return "El 'Nº' es necesario";
    }
    return '';
  };
  const validateZip = (value) => {
    if (!value.trim()) {
      return "El 'Código postal' es necesario";
    } else if (!regexZipCode.test(value.trim())) {
      return "Introduce un 'Código postal' válido";
    }
    return '';
  };
  const validateCity = (value) => {
    if (!value.trim()) {
      return "La 'Localidad' es necesaria";
    } else if (!regexName.test(value.trim())) {
      return "Introduce una 'Localidad' válida";
    }
    return '';
  };
  const validateIban = (value) => {
    if (!value.trim()) {
      return "El número de 'Iban' es necesario";
    } else if (!regexIban.test(value.trim())) {
      return "Introduce un 'Iban válido'";
    }
    return '';
  };
  const validateFrecuency = (value) => {
    if (!value || value === "-1") {
      return "Por favor, selecciona una frecuencia de pago";
    }
    return '';
  };
  
  const handleBlur = (e) => {
    const { name, value } = e.target;
    const error = validationsForm(name, value);
    setErrors({ ...errors, [name]: error });
  };
  
  const validationsForm = (name, value) => {
    switch (name) {
      case 'year':
      return validateYear(value);
    case 'month':
      return validateMonth(value);
    case 'business':
      return validateBusiness(value);
      case 'firstName':
        return validateFirstName(value);
      case 'lastName':
        return validateLastName(value);
      case 'birthDate':
        return validateBirthDate(value);
      case 'dni':
        return validateDni(value);
      case 'phone':
        return validatePhone(value);
      case 'email':
        return validateEmail(value);
      case 'streetType':
        return validateStreetType(value);
      case 'streetAddress':
        return validateStreetAddress(value);
      case 'streetNumber':
        return validateStreetNumber(value);
      case 'zip':
        return validateZip(value);
      case 'city':
        return validateCity(value);
      case 'iban':
        return validateIban(value);
      case 'frecuency':
        return validateFrecuency(value);
      default:
        return '';
    }
  };
  
  return { errors, setErrors, handleBlur, validationsForm};
};
