import React from "react";
import { Link } from "react-router-dom";
import { PropTypes } from "prop-types";
import { LinkSimple, Img } from "getbasecore/Atoms";
import "./footer.scss";
export const Footer = () => {
  return (
    <footer style={{ background: "#0079C8", paddingTop: "50px" }}>
      <div className="container container--grid">
        <Img src="./img/logosanitasfooter.png"></Img>
        <hr></hr>
        <div className="footer-links">
          <ul className="list list--inline list--icons--xs">
            <li>
              <LinkSimple href="https://www.sanitas.es/" target="_blank">
                <small className="small-1">©2024 Sanitas.es</small>
              </LinkSimple>
            </li>
            <li>
              <LinkSimple href="https://www.sanitas.es/sanitas/seguros/es/aviso_legal/index.html" target="_blank">
                <small className="small">Aviso legal</small>
              </LinkSimple>{" "}
            </li>
            <li>
              <Link to="/legal" target="_blank">
                <small className="small">Política de privacidad</small>
              </Link>
            </li>
          </ul>
          <ul className="list list--inline list--icons--xs">
            <li>
              <LinkSimple href="https://www.facebook.com/SanitasOficial/" target="_blank">
                <Img src="img/facebook.png"></Img>
              </LinkSimple>
            </li>
            <li>
              <LinkSimple href="https://twitter.com/Sanitas" target="_blank">
                <Img src="img/twitter.png"></Img>
              </LinkSimple>
            </li>
            <li>
              <LinkSimple href="https://www.youtube.com/user/Sanitastv" target="_blank">
                <Img src="img/youtube.png"></Img>
              </LinkSimple>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};
