import React from "react";
import { createRoot } from "react-dom/client"; 
import { AppRouter } from "./routers/AppRouter.js";
import reportWebVitals from "./reportWebVitals";
import "getbasecore/src/utils/reset/core_reset.scss";
import "getbasecore/src/utils/grid-layout/core_grid-layout.scss";
import "getbasecore/src/components/atoms/Typography/core_typography.scss";

// Utiliza createRoot en lugar de ReactDOM.render
createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <AppRouter />
  </React.StrictMode>
);

reportWebVitals();
