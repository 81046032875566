import { useState, useRef, useEffect } from "react";
import { Link } from 'react-router-dom';
import { BtnGroup, LinkSimple, Icon } from "getbasecore/Atoms";
import { BtnSimple} from "../../atoms/BtnSimple/BtnSimple";
import { useFormContext } from "../../../contexts/FormContext";
import { Hero } from "../../organisms/Hero/hero";
import { Card } from "../../organisms/Card/Card";
import { Tooltip } from "react-tooltip";
import { FormInputSimple } from "../../atoms/FormInputSimple/FormInputSimple";
import { FormSelectSimple } from "../../atoms/FormInputSimple/FormSelectSimple";
import { ModalSimple } from "../../molecules/ModalSimple/ModalSimple";
import {
  years,
  streetTypes,
  allBusiness,
  allFrecuencies,
} from "../../../data/constants";
import "./step5.scss";
export const Step5 = ({
  onNext,
  filteredMonths,
  
  handleInputChange,
  formDataAddPerson,
  setFormDataAddPerson,
  showForm,
  setShowForm,
  getBeneficiaryIndex,
  handleAddPerson,
  handleInputChangeAddPerson,
  handleRemovePerson,
  handleBack,
  handleConfirm,
  handleSubmit,
  confirmed,
  setConfirmed,
}) => {
  const { formData, setFormData, totalPrice, setTotalPrice } = useFormContext();

  const [editMode, setEditMode] = useState(false);
  const [total, setTotal] = useState(0);

  const changeEditMode = (editMode) => {
    setEditMode(editMode);
    if (editMode) {
      scrollMeTo(scrollRef);
    }
  };

  const scrollRef = useRef(null);
  const scrollMeTo = (ref) => {
    ref.current.scrollIntoView();
  };

  const isValidForm = () => {
    // Función para calcular la edad a partir de la fecha de nacimiento
    function calculateAge(birthDateStr) {
      const today = new Date();
      const birthDate = new Date(birthDateStr);
      let age = today.getFullYear() - birthDate.getFullYear();
      const month = today.getMonth() - birthDate.getMonth();
      if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    }

    // Validación de los datos personales del tomador de la póliza
    const isTomadorValid =
      formData.business !== "" &&
      formData.business !== "-1" &&
      formData.firstName !== "" &&
      formData.lastName !== "" &&
      formData.birthDate !== "" &&
      calculateAge(formData.birthDate) >= 18 && // El campo birthDate no puede estar vacío y la persona debe ser mayor o igual a 18 años
      formData.dni !== "" &&
      formData.phone !== "" &&
      formData.email !== "" &&
      formData.selectedOption !== "" &&
      formData.streetAddress !== "" &&
      formData.streetNumber !== "" &&
      formData.zip !== "" &&
      formData.city !== "" &&
      formData.streetType !== "" &&
      formData.iban !== "" &&
      formData.frecuency !== "" &&
      formData.frecuency !== "-1" &&
      formData.legal1 !== false;

    // Si no hay beneficiarios, solo se valida el tomador
    if (!showForm) {
      return isTomadorValid;
    }

    // Validación de los beneficiarios si se han añadido
    const areBeneficiariesValid = formDataAddPerson.every((beneficiary) => {
      // Verifica si el beneficiario tiene más de 14 años
      const today = new Date();
      const birthDate = new Date(beneficiary[`birthDate${beneficiary.id}`]);
      const age = today.getFullYear() - birthDate.getFullYear();
      const isBeneficiaryOver14 = age > 14;

      // Si es menor de 14 años, el DNI no es obligatorio
      if (!isBeneficiaryOver14) {
        return (
          beneficiary[`firstName${beneficiary.id}`] !== "" &&
          beneficiary[`lastName${beneficiary.id}`] !== "" &&
          beneficiary[`birthDate${beneficiary.id}`] !== "" &&
          beneficiary[`gender${beneficiary.id}`] !== "" &&
          beneficiary[`relationship${beneficiary.id}`] !== ""
        );
      } else {
        // Si es mayor de 14 años, se requiere el DNI
        return (
          beneficiary[`firstName${beneficiary.id}`] !== "" &&
          beneficiary[`lastName${beneficiary.id}`] !== "" &&
          beneficiary[`birthDate${beneficiary.id}`] !== "" &&
          beneficiary[`relationship${beneficiary.id}`] !== "" &&
          beneficiary[`gender${beneficiary.id}`] !== "" &&
          beneficiary[`dni${beneficiary.id}`] !== ""
        );
      }
    });

    return isTomadorValid && areBeneficiariesValid;
  };
 // Función para calcular el total
 const totalMoney = () => {
  const frecuency = formData.frecuency;
  let multiplier = 1;

  switch (frecuency) {
      case "Semestre":
          multiplier = 6;
          break;
      case "Trimestre":
          multiplier = 4;
          break;
      case "Año":
          multiplier = 12;
          break;
      default:
          break;
  }

  const calculatedTotal = totalPrice * multiplier; // Calcula el total
  return calculatedTotal; // Redondea el total a dos decimales
};


// Esta función se ejecutará cada vez que formData cambie
useEffect(() => {
  const newTotal = totalMoney(); // Calcula el nuevo total
  setTotal(newTotal); // Actualiza el estado de total
}, [formData]); // Ejecuta este efecto cada vez que formData cambie


  return (
    <>
      <Hero
        css={"step5"}
        imgSrc={"img/logosanitassinfondoazul.png"}
        securance={formData.securance}
        price={totalPrice.toFixed(2) + "€"}></Hero>
      <Card css="container container--grid step">
        <LinkSimple css="button-back" aria="" onClick={handleBack}>
          <Icon css="icon--xs" icon="arrow-left" /> Volver
        </LinkSimple>
        <p ref={scrollRef} className="h2.2">
          Resumen de tu seguro dental
        </p>
        <div className="summary">
          <Card css="card-summary">
            <p className="h3 title">1. Seguro seleccionado</p>
            <div className="section-summary-info">
              <div className="text">
                <p className="h5.2">Tu selección</p>
                <div>{formData.securance}</div>
              </div>
              <div className="text">
                <p className="h5.2">Precio</p>
                <div>{formData.price}</div>
              </div>
            </div>
          </Card>
          <Card css="card-summary">
            <p className="h3 title">2. Elige cuándo quieres empezar</p>
            <div className="section-summary-info">
              <div className="text">
                <p className="h5.2">Año</p>
                {editMode ? (
                  <FormSelectSimple
                    label="Año *"
                    id="year"
                    name="year"
                    hideDefault={formData.year}
                    value={formData.year}
                    onChange={(e) => handleInputChange(e)}>
                    {years.map((yearNumber, index) => (
                      <option key={index} selected={yearNumber === formData.year}>
                        {yearNumber}
                      </option>
                    ))}
                  </FormSelectSimple>
                ) : (
                  <div>{formData.year}</div>
                )}
              </div>
              <div className="text">
                <p className="h5.2">Mes</p>
                {editMode ? (
                  <FormSelectSimple
                    label="Mes *"
                    id="month"
                    name="month"
                    value={formData.month}
                    hideDefault={formData.month}
                    onChange={(e) => handleInputChange(e)}>
                    {filteredMonths.map((monthName, index) => (
                      <option key={index} selected={monthName === formData.month}>
                        {monthName}
                      </option>
                    ))}
                  </FormSelectSimple>
                ) : (
                  <div>{formData.month}</div>
                )}
              </div>
            </div>
          </Card>
          <Card css="card-summary personalData">
            <p className="h3 title">3. Rellena tus datos personales</p>
            <div className="text section-summary-business">
              <p className="h5.2">Área de negocio</p>
              {editMode ? (
                <FormSelectSimple
                  label="Área de negocio *"
                  id="business"
                  name="business"
                  value={formData.business}
                  hideDefault={formData.business}
                  onChange={(e) => handleInputChange(e)}>
                  {allBusiness.map((businessName, index) => (
                    <option key={index} selected={businessName === formData.business}>
                      {businessName}
                    </option>
                  ))}
                </FormSelectSimple>
              ) : (
                <div>{formData.business}</div>
              )}
            </div>
            <section className="section-summary-personalData">
              <p className="h5 personalData-title">Datos tomador de la póliza</p>
              <div className="text">
                <p className="h5.2">Nombre</p>
                {editMode ? (
                  <FormInputSimple
                    type="text"
                    name="firstName"
                    id="firstName"
                    value={formData.firstName}
                    placeholder="Nombre *"
                    required
                    onChange={(e) => handleInputChange(e)}
                  />
                ) : (
                  <div>{formData.firstName}</div>
                )}
              </div>
              <div className="text">
                <p className="h5.2">Apellidos</p>
                {editMode ? (
                  <FormInputSimple
                    label=""
                    type="text"
                    name="lastName"
                    id="lastName"
                    value={formData.lastName}
                    placeholder="Apellidos *"
                    required
                    onChange={(e) => handleInputChange(e)}
                  />
                ) : (
                  <div>{formData.lastName}</div>
                )}
              </div>
              <div className="text">
                <p className="h5.2">Fecha de nacimiento</p>
                {editMode ? (
                  <FormInputSimple
                  validation ="date"
                    label=""
                    type="date"
                    name="birthDate"
                    id="birthDate"
                    value={formData.birthDate}
                    placeholder="Fecha de nacimiento *"
                    max={new Date().toJSON().slice(0, 10)}
                    required
                    onChange={(e) => handleInputChange(e)}
                  />
                ) : (
                  <div>{formData.birthDate}</div>
                )}
              </div>
              <div className="text">
                <p className="h5.2">DNI</p>
                {editMode ? (
                  <FormInputSimple
                    label=""
                    type="text"
                    name="dni"
                    id="dni"
                    value={formData.dni}
                    placeholder="DNI *"
                    required
                    maxLength={8} // You can adjust the length according to your country's rules
                    onChange={(e) => handleInputChange(e)}
                  />
                ) : (
                  <div>{formData.dni}</div>
                )}
              </div>
              <div className="text">
                <p className="h5.2">Teléfono</p>
                {editMode ? (
                  <FormInputSimple
                    label=""
                    type="tel"
                    name="phone"
                    id="phone"
                    value={formData.phone}
                    placeholder="Teléfono *"
                    required
                    pattern="^(((\+|00)34)?([6-7][0-9]{8}|[8-9][1-9][0-9]{7})|(\+|00)376([3-46][0-9]{5}|[7-8][0-9]{5})|(\+|00)(?!(34|376|212|351|33|49|44|350))[0-9]{4,16})$"
                    onChange={(e) => handleInputChange(e)}
                  />
                ) : (
                  <div>{formData.phone}</div>
                )}
              </div>
              <div className="text">
                <p className="h5.2">Email</p>
                {editMode ? (
                  <FormInputSimple
                    label=""
                    type="text"
                    name="email"
                    id="email"
                    value={formData.email}
                    placeholder="Email *"
                    required
                    pattern="^[^@\s]+@[^@\s]+\.[A-Za-z]{2,}$"
                    onChange={(e) => handleInputChange(e)}
                  />
                ) : (
                  <div>{formData.email}</div>
                )}
              </div>
              <div className="text">
                <p className="h5.2">¿El tomador es el primer asegurado?</p>
                {editMode ? (
                  <div className="form__group radio-inputs">
                    <div className="radio-simple">
                      <input
                        id="yes"
                        value="yes"
                        name="selectedOption"
                        type="radio"
                        checked={formData.selectedOption === "yes"}
                        onChange={(e) => handleInputChange(e)}
                      />
                      <label htmlFor="yes">Sí</label>
                    </div>
                    <div className="radio-simple">
                      <input
                        id="no"
                        value="no"
                        name="selectedOption"
                        type="radio"
                        checked={formData.selectedOption === "no"}
                        onChange={(e) => handleInputChange(e)}
                      />
                      <label htmlFor="no">No</label>
                    </div>
                  </div>
                ) : (
                  <div>{formData.selectedOption === "yes" ? "Sí" : "No"}</div>
                )}
              </div>
            </section>
            <section className="section-summary-direction">
              <p className="h5 direction-title">Dirección</p>
              <div className="text">
                <p className="h5.2">Tipo de vía</p>
                {editMode ? (
                  <FormSelectSimple
                    label="Tipo de vía *"
                    id="streetType"
                    name="streetType"
                    hideDefault={formData.streetType}
                    required
                    onChange={(e) => handleInputChange(e)}>
                    {streetTypes.map((streetType, index) => (
                      <option key={index} value={streetType} selected={streetType === formData.streetType}>
                        {streetType}
                      </option>
                    ))}
                  </FormSelectSimple>
                ) : (
                  <div>{formData.streetType}</div>
                )}
              </div>
              <div className="text">
                <p className="h5.2">Nombre de la vía</p>
                {editMode ? (
                  <FormInputSimple
                    type="text"
                    name="streetAddress"
                    id="streetAddress"
                    value={formData.streetAddress}
                    placeholder="Nombre de la vía *"
                    label=""
                    required
                    onChange={(e) => handleInputChange(e)}
                  />
                ) : (
                  <div>{formData.streetAddress}</div>
                )}
              </div>
              <div className="text">
                <p className="h5.2">Nº</p>
                {editMode ? (
                  <FormInputSimple
                    type="text"
                    name="streetNumber"
                    id="streetNumber"
                    value={formData.streetNumber}
                    placeholder="Nº *"
                    label=""
                    required
                    onChange={(e) => handleInputChange(e)}
                  />
                ) : (
                  <div>{formData.streetNumber}</div>
                )}
              </div>
              <div className="text">
                <p className="h5.2">Piso, puerta..</p>
                {editMode ? (
                  <FormInputSimple
                    type="text"
                    name="flat"
                    id="flat"
                    value={formData.flat}
                    placeholder="Piso, puerta.."
                    label=""
                    onChange={(e) => handleInputChange(e)}
                  />
                ) : (
                  <div>{formData.flat}</div>
                )}
              </div>
              <div className="text">
                <p className="h5.2">CP</p>
                {editMode ? (
                  <FormInputSimple
                    type="number"
                    name="zip"
                    id="zip"
                    value={formData.zip}
                    pattern="/^\d{5}$/"
                    maxLength={5}
                    placeholder="CP *"
                    label=""
                    required
                    onChange={(e) => handleInputChange(e)}
                  />
                ) : (
                  <div>{formData.zip}</div>
                )}
              </div>
              <div className="text">
                <p className="h5.2">Localidad</p>
                {editMode ? (
                  <FormInputSimple
                    type="text"
                    name="city"
                    id="city"
                    value={formData.city}
                    placeholder="Localidad *"
                    label=""
                    required
                    onChange={(e) => handleInputChange(e)}
                  />
                ) : (
                  <div>{formData.city}</div>
                )}
              </div>
            </section>
            {showForm &&
              formDataAddPerson.map(
                (
                  data,
                  index // Renderiza un formulario para cada conjunto de datos en el estado formDataAddPerson
                ) => (
                  <div key={data.id}>
                    <legend className="h5 beneficiary-title">
                      Datos del beneficiario {getBeneficiaryIndex(index)}
                    </legend>
                    <fieldset className="section-summary-beneficiaryData">
                      {/* <LinkSimple onClick={() => handleRemovePerson(data.id)}>Eliminar beneficiario</LinkSimple> */}
                      <div className="text">
                        <p className="h5.2">Nombre</p>
                        {editMode ? (
                          <FormInputSimple
                            type="text"
                            name={`firstName${data.id}`}
                            id={`firstName${data.id}`}
                            value={data[`firstName${data.id}`]}
                            placeholder="Nombre *"
                            required
                            onChange={(e) => handleInputChangeAddPerson(index, e)} // Maneja el cambio en los campos del formulario
                          />
                        ) : (
                          <div>{data[`firstName${data.id}`]}</div>
                        )}
                      </div>

                      <div className="text">
                        <p className="h5.2">Apellidos</p>
                        {editMode ? (
                          <FormInputSimple
                            type="text"
                            name={`lastName${data.id}`}
                            id={`lastName${data.id}`}
                            value={data[`lastName${data.id}`]}
                            placeholder="Apellidos *"
                            required
                            onChange={(e) => handleInputChangeAddPerson(index, e)}
                          />
                        ) : (
                          <div>{data[`lastName${data.id}`]}</div>
                        )}
                      </div>
                      <div className="text">
                        <p className="h5.2">Fecha de Nacimiento</p>
                        {editMode ? (
                         <FormInputSimple
                         validation ="date"
                         type="date"
                         name={`birthDate${data.id}`}
                         id={`birthDate${data.id}`}
                         value={data[`birthDate${data.id}`]}
                         placeholder="Fecha de nacimiento *"
                         max={new Date().toJSON().slice(0, 10)}
                         required
                         onChange={(e) => handleInputChangeAddPerson(index, e)}
                       />
                        ) : (
                          <div>{data[`birthDate${data.id}`]}</div>
                        )}
                      </div>
                      <div className="text">
                        <p className="h5.2">DNI</p>
                        {editMode ? (
                         <FormInputSimple
                         type="text"
                         name={`dni${data.id}`}
                         id={`dni${data.id}`}
                         value={data[`dni${data.id}`]}
                         placeholder="DNI"
                         required
                         maxLength={8}
                         onChange={(e) => handleInputChangeAddPerson(index, e)}
                       />
                        ) : (
                          <div>{data[`dni${data.id}`]}</div>
                        )}
                      </div>
                      <div className="text">
                        <p className="h5.2">Parentesco</p>
                        {editMode ? (
                        <FormInputSimple
                        type="text"
                        name={`relationship${data.id}`}
                        id={`relationship${data.id}`}
                        value={data[`relationship${data.id}`]}
                        placeholder="Parentesco con el tomador *"
                        required
                        onChange={(e) => handleInputChangeAddPerson(index, e)}
                      />
                        ) : (
                          <div>{data[`relationship${data.id}`]}</div>
                        )}
                      </div>
                      <div className="text">
                        <p className="h5.2">Sexo</p>
                        {editMode ? (
                              <div className="form__group radio-inputs">
                          <div className="radio-simple">
                            <input
                              id={`female${data.id}`}
                              value="female"
                              name={`gender${data.id}`}
                              type="radio"
                              checked={data[`gender${data.id}`] === "female"}
                              onChange={(e) => handleInputChangeAddPerson(index, e)}
                            />
                            <label htmlFor={`female${data.id}`}>Mujer</label>
                          </div>
                          <div className="radio-simple">
                            <input
                              id={`male${data.id}`}
                              value="male"
                              name={`gender${data.id}`}
                              type="radio"
                              checked={data[`gender${data.id}`] === "male"}
                              onChange={(e) => handleInputChangeAddPerson(index, e)}
                            />
                            <label htmlFor={`male${data.id}`}>Hombre</label>
                          </div>
                        </div>
                        ) : (
                          
                          <div>{data[`gender${data.id}`] === "male" ? "Hombre" : "Mujer"}</div>
                        )}
                      </div>
                    </fieldset>
                  </div>
                )
              )}
          </Card>
          <Card css="card-summary">
            <p className="h3 title">4. Modalidades de pago</p>
            <div className="section-summary-info">
              <div className="text">
                <p className="h5.2">Iban</p>
                <div contentEditable={true} suppressContentEditableWarning={true}>
                  {formData.iban}
                </div>
              </div>
              <div className="text">
                <p className="h5.2">Pagar cada</p>
                {editMode ? (
                  <FormSelectSimple
                    label="Pagar cada *"
                    id="frecuency"
                    name="frecuency"
                    value={formData.frecuency}
                    hideDefault={formData.frecuency}
                    onChange={(e) => handleInputChange(e)}>
                    {allFrecuencies.map((frecuencyName, index) => (
                      <option key={index} selected={frecuencyName === formData.frecuency}>
                        {frecuencyName}
                      </option>
                    ))}
                  </FormSelectSimple>
                ) : (
                  <div>{formData.frecuency}</div>
                )}
              </div>
              <div className="text">
                <p className="h5.2">Total</p>
                <p>{total.toFixed(2) + "€"}</p>
                </div>
            </div>
          </Card>
          <Card>
            <fieldset>
              <legend className="h6"> </legend>
              <div className="form__group">
                <div className="checkbox-simple">
                  <input
                    type="checkbox"
                    name="legal1"
                    id="checklegal1"
                    checked={formData.legal1}
                    onChange={(e) => handleInputChange({ target: { name: e.target.name, value: e.target.checked } })}
                  />
                  <label htmlFor="checklegal1">
                    He leído y acepto todas{" "}
                    <button type="button" className="tooltip-1">
                      <p className="link-6">las condiciones y servicios*</p>
                    </button>
                    <Tooltip anchorSelect=".tooltip-1" place='left-end' events={["click"]}>
                      <small className="react-tooltip__content conditions">
                        <p className="h5.3">Términos y condiciones legales</p>
                        <p>
                        <br></br>
                          Promoción exclusiva para empleados y familiares de empleados de hasta primer grado de
                          sociedades pertenecientes al Grupo Sanitas. 
                          <br></br>
                          <br></br>
                          (1) Descuento aplicable a tratamientos iniciados
                          en los Centros Dentales Milenium identificados en www.sanitas.es/clinicasdentales. Descuento
                          aplicable sobre las tarifas correspondientes al seguro de Sanitas contratado o, en el caso de
                          no tener contratado ningún seguro, a aquellas tarifas que le fueran aplicables. No acumulable
                          a otras promociones. 
                          <br></br>
                          <br></br>
                          (2) El asegurado tiene incluido en su póliza –sin coste, salvo aquellos
                          asegurados que tengan contratado la modalidad del producto con copago- la realización de dos
                          de los siguientes tratamientos cada año de vigencia de la misma: empastes, reconstrucciones y
                          obturaciones en diente. Con independencia del número de anualidades que transcurran durante el
                          periodo total de vigencia de la póliza, el asegurado podrá realizarse una suma total de 8 de
                          los anteriores tratamientos. Si el asegurado precisa de algún tratamiento por encima de dicho
                          límite, deberá de abonar según la tarifa vigente. 
                          <br></br>
                          <br></br>
                          (3) Prima neta mensual por asegurado a la
                          que se aplicará el recargo del Consorcio de Compensación de Seguros (0,15% sobre la prima neta
                          anual. Primas válidas para contrataciones formalizadas entre el 01/01/2024. y el 31/12/2024,
                          ambos inclusive. Primas netas aplicables hasta el 01/12/2024. Primas válidas para pólizas
                          independientes. Permanencia anual.
                        </p>
                      </small>
                    </Tooltip>
                  </label>
                </div>
              </div>
              <div className="form__group">
                <div className="checkbox-simple">
                  <input
                    type="checkbox"
                    name="legal2"
                    id="checklegal2"
                    checked={formData.legal2}
                    onChange={(e) => handleInputChange({ target: { name: e.target.name, value: e.target.checked } })}
                  />
                  <label htmlFor="checklegal2">
                    Acepto todos los tratamientos de mis datos indicados a continuación* (Su no aceptación NO impide la
                    contratación del Seguro)
                  </label>
                </div>
              </div>
            </fieldset>
            <Card>
              <fieldset className="fieldset-sublegal">
                <div className="form__group radio-inputs">
                  <div className="radio-simple">
                    <input
                      id="yesSubLegal1"
                      value="yes"
                      name="subLegal1"
                      type="radio"
                      checked={formData.subLegal1 === "yes"}
                      onChange={(e) => handleInputChange(e)}
                    />
                    <label htmlFor="yesSubLegal1">Sí</label>
                  </div>
                  <div className="radio-simple">
                    <input
                      id="noSubLegal1"
                      value="no"
                      name="subLegal1"
                      type="radio"
                      checked={formData.subLegal1 === "no"}
                      onChange={(e) => handleInputChange(e)}
                    />
                    <label htmlFor="noSubLegal1">No</label>
                  </div>
                </div>
                <div className="h6">
                  Acepto el tratamiento de mis datos para la elaboración de perfiles (incluso con datos de salud){" "}
                  <button type="button" className="hero-tooltip-1">
                    <p className="link-6"> (i)</p>
                  </button>
                  <Tooltip anchorSelect=".hero-tooltip-1" place="top" events={["click"]}>
                    <small className="react-tooltip__content">
                      <p className="h5.3">Si No Elaboración de perfiles y toma de decisiones automatizadas</p>
                      Consiento el tratamiento de mis datos personales con el fin de que Sanitas lleve a cabo un
                      análisis de mis intereses y necesidades con base en los datos proporcionados por mi incluyendo
                      pero sin limitarse a mis datos de salud, aquellos datos personales que se hayan generado como
                      consecuencia del servicio prestado por Sanitas o que haya obtenido Sanitas por otros medios,
                      pudiendo incluir dicho tratamiento la toma de decisiones automatizadas.
                    </small>
                  </Tooltip>
                </div>
              </fieldset>
              <fieldset className="fieldset-sublegal">
                <div className="form__group radio-inputs">
                  <div className="radio-simple">
                    <input
                      id="yesSubLegal2"
                      value="yes"
                      name="subLegal2"
                      type="radio"
                      checked={formData.subLegal2 === "yes"}
                      onChange={(e) => handleInputChange(e)}
                    />
                    <label htmlFor="yesSubLegal2">Sí</label>
                  </div>
                  <div className="radio-simple">
                    <input
                      id="noSubLegal2"
                      value="no"
                      name="subLegal2"
                      type="radio"
                      checked={formData.subLegal2 === "no"}
                      onChange={(e) => handleInputChange(e)}
                    />
                    <label htmlFor="noSubLegal2">No</label>
                  </div>
                </div>
                <div className="h6">
                  Acepto el tratamiento de mis datos para el envío de comunicación comerciales (incluso por vía
                  electrónica){" "}
                  <button type="button" className="hero-tooltip-2">
                    <p className="link-6"> (i)</p>
                  </button>
                  <Tooltip anchorSelect=".hero-tooltip-2" place="top" events={["click"]}>
                    <small className="react-tooltip__content">
                      <p className="h5.3">
                        Si No Recibir información (incluso por vía electrónica) del Grupo Sanitas o de terceros incluso
                        si no llego a contratar
                      </p>
                      Consiento el tratamiento de mis datos personales para finalidades promocionales de productos y
                      servicios de Sanitas o de terceras empresas, incluyendo el envío por medios electrónicos de
                      comunicaciones comerciales o equivalentes por parte de Sanitas, incluso aunque no llegue a
                      contratar el seguro.
                    </small>
                  </Tooltip>
                </div>
              </fieldset>
              <fieldset className="fieldset-sublegal">
                <div className="form__group radio-inputs">
                  <div className="radio-simple">
                    <input
                      id="yesSubLegal3"
                      value="yes"
                      name="subLegal3"
                      type="radio"
                      checked={formData.subLegal3 === "yes"}
                      onChange={(e) => handleInputChange(e)}
                    />
                    <label htmlFor="yesSubLegal3">Sí</label>
                  </div>
                  <div className="radio-simple">
                    <input
                      id="noSubLegal3"
                      value="no"
                      name="subLegal3"
                      type="radio"
                      checked={formData.subLegal3 === "no"}
                      onChange={(e) => handleInputChange(e)}
                    />
                    <label htmlFor="noSubLegal3">No</label>
                  </div>
                </div>
                <div className="h6">
                  Acepto la cesión de mis datos a empresas del grupo (incluso de salud) con fines de investigación
                  científica o estadística y con fines comerciales, o a terceras empresas con fines comerciales.{" "}
                  <button type="button" className="hero-tooltip-3">
                    <p className="link-6"> (i)</p>
                  </button>
                  <Tooltip anchorSelect=".hero-tooltip-3" place="top" events={["click"]}>
                    <small className="react-tooltip__content">
                      <p className="h5.3">
                        Si No Tratar/ceder mis datos con fines científicos y estadísticos al grupo Sanitas y fines
                        comerciales a terceros
                      </p>
                      Consiento la cesión y el tratamiento de mis datos personales por las entidades del grupo de
                      Sanitas con fines de investigación científica y/o estadística y fines comerciales, así como de las
                      terceras empresas colaboradoras identificadas en la Información Adicional con la finalidad de
                      remitirme información comercial relacionada con productos y servicios financieros, seguros,
                      servicios socio-sanitarios y/o de salud o bienestar, incluyendo el envío de comunicaciones
                      comerciales por medios electrónicos.
                    </small>
                  </Tooltip>
                </div>
              </fieldset>
            </Card>
          </Card>
        </div>
        <Card>
          <BtnGroup>
            {/*editMode && (
              <BtnSimple type="button" css="btn-simple--1" aria="" onClick={handleConfirm}>
                Confirmar
                <Icon css="icon--xs" icon="arrow-right"></Icon>
              </BtnSimple>
            )*/}
            {!editMode && (
              <BtnSimple
                id="Send"
                type="button"
                css="btn-simple--1"
                aria=""
                onClick={handleSubmit}
                disabled={isValidForm() === false}>
                Enviar
                <Icon css="icon--xs" icon="arrow-right"></Icon>
              </BtnSimple>
            )}

            <input type="submit" onClick={handleSubmit}></input>
            <BtnSimple id="EditMode" type="button" css="btn-simple--1" aria="" onClick={() => changeEditMode(!editMode)}>
              {editMode ? "Confirmar cambios" : "Modificar"}
              <Icon css="icon--xs" icon="arrow-right" onClick={() => scrollMeTo(scrollRef)}></Icon>
            </BtnSimple>
          </BtnGroup>
          <div className="link-legal">
          <p >Contactaremos contigo para proporcionarte información sobre nuestro seguro dental. Consulta la <Link className="link-8" target="_blank" to="/legal">información de privacidad.</Link></p></div>
        </Card>
      </Card>
    </>
  );
};
