import { useState } from "react";
import { useValidations } from "services/validations";
import {  BtnGroup, LinkSimple, Icon } from "getbasecore/Atoms";
import { BtnSimple} from "../../atoms/BtnSimple/BtnSimple";
import { useFormContext } from "../../../contexts/FormContext";
import { Form } from "getbasecore/Molecules";
import { Hero } from "../../organisms/Hero/hero";
import { ProgressSteps } from "../../atoms/ProgressSteps/ProgressSteps";
import { FormInputSimple } from "../../atoms/FormInputSimple/FormInputSimple";
import { FormSelectSimple } from "../../atoms/FormInputSimple/FormSelectSimple";
import { Card } from "../../organisms/Card/Card";
import { allFrecuencies } from "../../../data/constants";
import "./step4.scss";
export const Step4 = ({ onNext, handleInputChange, handleBack, errors, handleBlur }) => {
  const { formData, setFormData, totalPrice, setTotalPrice } = useFormContext();
  const [isValid, setIsValid] = useState(false);
  const { setErrors } = useValidations();
  const handleValidation = () => {
    setErrors(validationsTotal(formData));
  };
  const validationsTotal = () => {
    let regexIban = /([a-zA-Z]{2})\s*\t*(\d{2})\s*\t*(\d{4})\s*\t*(\d{4})\s*\t*(\d{2})\s*\t*(\d{10})/;

    if (!formData.iban.trim()) {
      errors.iban = "El número de 'Iban' es necesario";
    } else if (!regexIban.test(formData.iban.trim())) {
      errors.iban = "Introduce un 'Iban válido";
    }
    if (!formData.frecuency && (formData.frecuency === "" || formData.frecuency === "-1")) {
      errors.frecuency = "Por favor, selecciona una frecuencia de pago";
    }
  };

  const onValidation = () => {
    // Validar el formulario
    if (isValidForm()) {
      // Si el formulario es válido, cambiar el estado de isValid a true
      setIsValid(true);
      onNext();
    } else {
      // Si el formulario no es válido, cambiar el estado de isValid a false
      setIsValid(false);
      handleValidation("iban", formData.iban);
      handleValidation("frecuency", formData.frecuency);
    }
  };

  const isValidForm = () => {
    const regexIban = /^(ES)\s*\t*(\d{2})\s*\t*(\d{4})\s*\t*(\d{4})\s*\t*(\d{2})\s*\t*(\d{10})/;
    const isValidIban = regexIban.test(formData.iban);
    const isValidFrequency = formData.frecuency !== "" && formData.frecuency !== "-1";

    return isValidIban && isValidFrequency;
  };

  return (
    <>
      <Hero
        css={"step4"}
        imgSrc={"img/logosanitassinfondoazul.png"}
        securance={formData.securance}
        price={totalPrice.toFixed(2) + "€"}></Hero>
      <Card css="container container--grid step">
        <LinkSimple css="button-back" aria="" onClick={handleBack}>
          <Icon css="icon--xs" icon="arrow-left" /> Volver
        </LinkSimple>
        <div>
          <p className="h3"id="titleStep4">4. Domiciliación de Pago</p>
        </div>
        <Form css="form form--simple step4">
          <fieldset className="fieldset-iban">
            <FormInputSimple
              id="iban"
              type="text"
              name="iban"
              value={formData.iban}
              maxLength={24}
              onChange={(e) => handleInputChange(e)}
              placeholder="Iban * ES0000000000000000000000"
              required
              onBlur={(e) => handleBlur(e)}></FormInputSimple>{" "}
            {errors.iban && <p className="error">{errors.iban}</p>}
          </fieldset>
          <fieldset className="fieldset-frecuency">
            <FormSelectSimple
              label="Pagar cada *"
              id="frecuency"
              name="frecuency"
              selected
              hideDefault={formData.frecuency}
              onBlur={(e) => handleBlur(e)}
              onChange={(e) => handleInputChange(e)}>
              {allFrecuencies.map((frecuency, index) => (
                <option key={index} value={frecuency} selected={frecuency === formData.frecuency}>
                  {frecuency}
                </option>
              ))}
            </FormSelectSimple>
            {errors.frecuency && <p className="error">{errors.frecuency}</p>}
          </fieldset>
        </Form>
      </Card>
      <Card>
        <ProgressSteps value={4} max={4}>
          <BtnGroup>
            <BtnSimple
              id="nextStep4"
              type="button"
              css={isValidForm() === false ? "btn-simple--1 invalid" : "btn-simple--1"}
              aria=""
              onClick={onValidation}
              >
              Finalizar
              <Icon css="icon--xs" icon="arrow-right"></Icon>
            </BtnSimple>
          </BtnGroup>
        </ProgressSteps>
      </Card>
    </>
  );
};
