import React from "react";
import { PropTypes } from "prop-types";
import "./core_form-input-simple.scss";
export const FormSelectSimple = ({
  label,
  name,
  children,
  additionalChildren,
  onChange,
  onBlur,
  hideDefault,
  validation,
}) => {
  return (
    <>
      <div className={`form__group ${validation}`}>
        <label htmlFor={name} className="show-sr">
          {label}
        </label>
        <select required className="form__select" name={name} id={name} onChange={onChange} onBlur={onBlur}>
          {!hideDefault && (
            <option disabled selected value="">
              {label}
            </option>
          )}
          {children}
        </select>
      </div>
      {additionalChildren}
    </>
  );
};

FormSelectSimple.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  hideDefault: PropTypes.bool,
  validation: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.element]),
  additionalChildren: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.element]),
};
