import { Hero } from "../../organisms/Hero/hero";
import { Card } from "../../organisms/Card/Card";
import "./step6.scss";
export const Step6 = () => {
  return (
    <>
      <Hero css={"step6"} imgSrc={"img/logosanitassinfondoazul.png"}></Hero>
      <Card css="container container--grid step6-card">
        <div className="step6-text">
          <p className="h3.2">¡Gracias!</p>
          <p className="h5.2">Hemos recibido tu solicitud y la tramitaremos a la mayor brevedad.</p>
        </div>
      </Card>
    </>
  );
};
