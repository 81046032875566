import React from "react";
import { PropTypes } from "prop-types";

import { BtnSimple, Icon} from "getbasecore/Atoms";
import { Card } from "../../organisms/Card/Card";

import "./click-card.scss";

export const ClickCard = ({children,
  onClick,
  css,
  className,
  buttonDiv,
  name,
  iconbtn,
  btnTxt,
}) => {
  return (
    
    <div className={className}>
      {children}
      <div className={buttonDiv}>
        <BtnSimple type="button" aria="" name={name} onClick={onClick} css={css}>
          {btnTxt}
          <Icon css="icon--xs" icon={iconbtn} />{" "}
        </BtnSimple>
        </div>
    </div>
  );
};

ClickCard.propTypes = {
  onClick: PropTypes.func,
  css: PropTypes.string,
  className: PropTypes.string,
  buttonDiv: PropTypes.string,
  icon: PropTypes.string,
};
