import { Hero } from "../components/organisms/Hero/hero";
import { Footer } from "../components/organisms/Footer/footer";
import { Card } from "../components/organisms/Card/Card";

export const Legal = () => {
  //let history = useHistory();

  return (
    <>
      <Hero css={"legal"} imgSrc={"img/logosanitassinfondoazul.png"}></Hero>
      <Card css="container container--grid">
        <div>
          <p className="h3 section-header">Política de Privacidad </p>

          <p>
            <span className="h5.5 subtitle-legal">Responsable del tratamiento:</span> SANITAS NUEVOS NEGOCIOS S.L.U.
            (“SANITAS”), con domicilio en c/ Ribera de Loira 52, 28042 de Madrid.{" "}
          </p>
          <p>
            SANITAS tratará tus datos personales con la finalidad de realizar la cotización del servicio, contactar
            contigo por vía telefónica, si lo considera oportuno, para resolver tus dudas, facilitarte la información
            que necesites relativa a nuestros servicios y su proceso de contratación, así como para prestarte asistencia
            durante el referido proceso. La base legal del tratamiento es la implementación de medidas precontractuales
            que otorgas al completar el formulario y enviarlo de forma voluntaria. Tus datos personales no se cederán a
            terceros, salvo por obligación legal y/o cuando resulte necesario para la prestación del servicio. Asimismo,
            para cumplir adecuadamente con la finalidad del tratamiento, SANITAS puede requerir que otros terceros
            prestadores de servicios de SANITAS accedan a tus datos personales en calidad de encargados del tratamiento,
            los cuales cuentan con las garantías adecuadas.
          </p>
          <p>
            Para realizar la cotización del servicio trataremos tus datos personales durante el periodo de 10 días desde
            tu última interacción con SANITAS, salvo que contemos con tu consentimiento para tratamientos ulteriores.
            Una vez finalizado dicho periodo de tiempo, mantendremos tus datos personales durante el plazo de
            prescripción de cualquier responsabilidad que pueda surgir como consecuencia del tratamiento. Información
            sobre transferencias internacionales en 
            <a
              className="link-7"
              href="https://www.sanitas.es/landings/seguros/rgpd/"
              target="_blank"
              rel="noopener noreferrer">
              www.sanitas.es/RGPD
            </a>
            .
          </p>
          <p>
            Puedes ejercer tus derechos de acceso, rectificación, supresión, portabilidad, limitación y oposición del
            tratamiento, a no ser objeto de decisiones basadas únicamente en el tratamiento automatizado de tus datos y
            a retirar el consentimiento otorgado en cualquier momento, mediante solicitud escrita, a la atención de
            ”LOPD” y remitida por correo postal a la dirección antes indicada, o por mail a 
            <a className="link-7" href="mailto:lopd@sanitas.es" target="_blank" rel="noopener noreferrer">
              lopd@sanitas.es
            </a>
            , adjuntando, si fuera necesario, en ambos casos documento acreditativo de tu identidad. Además, también
            tienes derecho a presentar una reclamación ante la autoridad de control pertinente{" "}
            <a className="link-7" href="https://www.aepd.es/" target="_blank" rel="noopener noreferrer">
              (www.aepd.es)
            </a>
            .{" "}
          </p>
          <p>
            Puedes contactar con el Delegado de Protección de Datos de Sanitas (DPO) a través del mail 
            <a className="link-7" href="mailto:dpo@sanitas.es" target="_blank" rel="noopener noreferrer">
              dpo@sanitas.es
            </a>
            . Más información en 
            <a
              className="link-7"
              href="https://www.sanitas.es/landings/seguros/rgpd/"
              target="_blank"
              rel="noopener noreferrer">
              www.sanitas.es/RGPD
            </a>
            .
          </p>
        </div>
      </Card>
      <Footer>salud</Footer>
    </>
  );
};
